import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0,2,6,5,8,9,3];

export const dictionary = {
		"/(app)": [10,[2]],
		"/(app)/buku": [~11,[2]],
		"/(auth)/create-password": [~17,[4]],
		"/(legal)/delete-account-app": [35,[6]],
		"/(handler)/discount-port/[product_code]": [~24,[5]],
		"/(handler)/download-aplikasi-tryout/[code]": [~25],
		"/(protected)/exams/[id]": [~39,[8]],
		"/(protected)/exams/[id]/in/[quiz_num]": [~40,[9]],
		"/(auth)/forgot-password": [~18,[4]],
		"/(handler)/free-exam": [~26,[5]],
		"/(handler)/invalid-user": [~27,[5]],
		"/(legal)/(documents)/kebijakan-privasi": [32,[6,7]],
		"/(legal)/kontak": [36,[6]],
		"/(app)/live-class": [~12,[2]],
		"/(auth)/login": [19,[4]],
		"/(auth)/logout": [~20,[4]],
		"/(legal)/lokasi": [~37,[6]],
		"/(payment)/order/checkout/[product_code]": [~38],
		"/(handler)/order/register/[product_code]": [~28,[5]],
		"/(handler)/redeem": [~29,[5]],
		"/(handler)/redeem/success/[code]": [~30,[5]],
		"/(auth)/register": [~21,[4]],
		"/(auth)/send-otp": [22,[4]],
		"/(protected)/settings": [~41,[8]],
		"/(handler)/suspended": [~31,[5]],
		"/(legal)/(documents)/syarat-dan-ketentuan": [33,[6,7]],
		"/(legal)/(documents)/tentang": [34,[6,7]],
		"/(app)/tryout": [13,[2]],
		"/(auth)/verification": [~23,[4]],
		"/(app)/video": [14,[2]],
		"/(app)/video/[id]/read-material": [~15],
		"/(app)/video/[id]/watch": [~16,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';